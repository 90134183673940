import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'

const ArtistContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 2rem auto;
  justify-content: space-around;
  align-items: center;
`

const StyledArtist = styled.div`
    img {
        max-width: 340px;
        margin: 1rem 0;
        padding: 1rem;
        
    }

    
`

const StyledViewMore = styled.p`
    color: black;
    font-family: "Roboto", sans-serif;
    padding: 1rem 3rem;
    text-align: right;
`

const LatestArtist = () => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulArtist(limit: 3) {
          edges{
            node{
              bandName
              slug
              bandLogo{
                fixed{
                  width
                  src
                }
              }
            }
          }
        }
      }
      
    `)
    return <><ArtistContainer>
        {data.allContentfulArtist.edges.map(edge => {
            return (
                <>
                
                
                 <a href={`artist/${edge.node.slug}`}>
                    <StyledArtist>
                        <img src={edge.node.bandLogo.fixed.src}/>
                    </StyledArtist>
                </a>
                
                </>
            )
            
        })}
       
    </ArtistContainer>
    <Link style={{textDecoration: 'none'}} to="/artists">
    <StyledViewMore>View All Artists</StyledViewMore></Link>
    </>
}

export default LatestArtist