import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import MainNav from "../components/MainNav"
import HeroVideo from "../components/videos/HeroVideo.mp4"

import styled from "styled-components"
import Logo from "../images/Logox150.png"
import HomeNews from "../components/HomeNews"
import SectionTitle from "../components/common/SectionTitle"
import LatestPodcasts from "../components/LatestPodcasts"
import Footer from "../components/Footer"
import AudioPlayer from "../components/AudioPlayerv2"

import LatestArtist from "../components/LatestArtist"
import Schedule from "../components/Schedule"

const StyledIndexPage = styled.div`
  #video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content-overlay {
      position: absolute;
      top: 100px;
      left: 50px;

      .logo-container {
        background-color: whitesmoke;
        border-radius: 50%;
        border: 2px solid #be1e2d;

        img {
          @media (max-width: 720px) {
            width: 100px;
          }
        }
      }
      @media (max-width: 630px) {
        top: 150px;
      }
    }

    h1 {
      position: absolute;
      top: 250px;
      left: 70px;
      font-size: 5em;
      font-family: Orbitron;
      color: yellow;
      -webkit-text-stroke: 2px black;
      @media (max-width: 930px) {
        width: 800px;
        left: 50%;
        margin-left: -400px;
        text-align: center;
      }

      @media (max-width: 800px) {
        max-width: 650px;
        left: 50%;
        margin-left: -325px;
        text-align: center;
        font-size: 4em;
      }

      @media (max-width: 650px) {
        max-width: 350px;
        left: 50%;
        margin-left: -175px;
      }
    }
    h2 {
      position: absolute;
      top: 380px;
      left: 90px;
      color: yellow;
      font-size: 2em;
      font-family: Orbitron;
      -webkit-text-stroke: 1px black;
      @media (max-width: 700px) {
        width: 650px;
        text-align: center;
        font-size: 1.5em;
        left: 50%;
        margin-left: -325px;
      }
      @media (max-width: 650px) {
        top: 440px;
        max-width: 350px;
        margin-left: -175px;
      }
    }
  }

  .header-container {
    position: absolute;
    top: 0;
    /* display: none; */
    animation-name: slide;
    animation-duration: 0.5s;
  }

  @keyframes slide {
    from {
      margin-top: -100px;
    }
    to {
      margin-top: 0;
    }
  }

  .index-nav {
    position: absolute;
    top: 100px;
    left: 40%;
    list-style-type: none;
    font-family: "Roboto", sans-serif;
    width: 450px;
    display: flex;
    justify-content: space-between;
    color: whitesmoke;
    @media (max-width: 850px) {
      left: 50%;
      margin-left: -200px;
    }
    @media (max-width: 650px) {
      top: 60px;
      font-size: 0.8em;
      max-width: 350px;
      padding: 0;
      margin-left: -175px;
    }

    li {
      &:hover {
        color: yellow;
        cursor: pointer;
      }
    }
  }

  .podcast-section {
    width: 100%;
    background-color: #6195bd;
    padding: 3rem 0;
  }

  .calendar {
    max-width: 1400px;
    margin: 0 auto;

    iframe {
      width: 100%;
      height: 600px;
    }
  }
`

const IndexPage = () => {
  const [displayHeader, setDisplayHeader] = useState("none")

  useEffect(() => {
    const handleScroll = e => {
      let y = window.scrollY

      if (y > 100) {
        setDisplayHeader("block")
      } else setDisplayHeader("none")
    }
    document.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <SEO
        title="Home"
        description="Listen live to the best in Nerdcore and Geek Rock music."
      />
      <StyledIndexPage>
        <div className="header-container" style={{ display: displayHeader }}>
          <MainNav />
        </div>
        <div id="video-container">
          <video playsInline autoPlay muted loop>
            <source src={HeroVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="content-overlay">
            <div className="logo-container">
              <img src={Logo} alt="" />
            </div>
          </div>
          <h1>Geek Life Radio</h1>
          <h2>#1 Source for "Geek Life" audio!</h2>
        </div>
        <SectionTitle title="Whats New" />
        <HomeNews />

        <SectionTitle title="Latest Podcasts" />
        <div className="podcast-section">
          <LatestPodcasts />
        </div>

        <SectionTitle title="Newest Artists" />
        <LatestArtist />
        <SectionTitle title="Weekly Schedule" />
        <Schedule />
      </StyledIndexPage>
      <AudioPlayer />
      <Footer />
    </>
  )
}

export default IndexPage
